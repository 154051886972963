<template>
  <div>
    <p>Sin resultados 0</p>
  </div>
</template>

<script>
export default {
  name: 'Admin',
  data() {
    return {};
  },
  methods: {},
  computed: {},
  mounted() {},
  components: {}
};
</script>
<style scoped></style>
